<template>
  <div>
    <b-row>
      <b-col cols="10">
        <h1>Active Chats</h1>
      </b-col>
      <b-col cols="3" class="mt-sm-3">
        <bot-selector @botChanged="changeBot" />
      </b-col>
      <b-col style="font-size: 1.5em">
        <b-icon-arrow-repeat class="btn-refresh mt-4" @click="refresh" />
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col cols="4" class="chat-selector-container">
        <b-list-group>
          <b-list-group-item
            v-for="item in items"
            :key="item.chatId"
            class="btn-list"
            @click="getChat(item)"
          >
            <h6 class="wrap-text">{{ item.chatId }}</h6>
            <small>Start time: {{ item.connectTime }}</small>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="8">
        <div class="chat-container">
          <div v-if="isBusy" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <chat-text :chats="chats" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import StreamChatLog from '@/mixins/StreamChatLog';
import ChatText from '@/components/ChatHistories/ChatText';
import BotSelector from '@/components/UI/BotSelector';

export default {
  /*global _*/
  mixins: [httpclient, StreamChatLog],
  components: { ChatText, BotSelector },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    this.changeBot(botId);
    setInterval(this.getActiveChats, 60000);
  },
  data() {
    return {
      bots: [],
      queryParams: {
        fromDate: null,
        toDate: null,
        botId: null,
        chatId: null,
        speaker: null,
        speakerName: null,
        message: null,
        messageType: null,
        limit: 1000,
      },
      items: [],
      chats: [],
    };
  },
  watch: {
    newChatLog(newValue) {
      // eslint-disable-next-line no-console
      console.log({ newValue: newValue });

      let data = {};

      try {
        data = JSON.parse(newValue);
      } catch (error) {
        return;
      }

      let botId = parseInt(data.botId);

      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('botId') && botId === this.queryParams.botId) {
        let chatId = data.chatId;

        if (!_.some(this.items, { chatId: chatId })) {
          this.items.push({ chatId: chatId, connectTime: data.datetime });
        }

        if (this.queryParams.chatId === chatId) {
          let chat = [
            '',
            '',
            chatId,
            data.datetime,
            data.speaker,
            data.speakerName,
            '',
            JSON.stringify(data.msg),
          ];
          this.chats.push(chat);
        }
      }
    },
  },
  methods: {
    changeBot(botId) {
      this.queryParams.botId = botId;
      this.getActiveChats();
    },
    refresh() {
      this.getActiveChats();
      this.chats = [];
    },
    getActiveChats() {
      const vm = this;

      vm.httpclient
        .get('api/chathistories/ongoing', {
          params: { botId: vm.queryParams.botId },
        })
        .then(function (resp) {
          if (resp.data) {
            let list = resp.data;
            let keys = _.keys(list);
            vm.items = [];
            for (let i = 0; i < _.keys(list).length; i++) {
              vm.items.push(list[keys[i]]);
            }
          }
        })
        .catch(function () {
          vm.chats = [];
        });
    },
    getChat(item) {
      const vm = this;
      vm.queryParams.chatId = item.chatId;
      vm.httpclient
        .post('api/chathistories/getlist', vm.queryParams)
        .then(function (resp) {
          if (resp.data) {
            vm.chats = resp.data;
          }
        })
        .catch(function () {
          vm.chats = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-refresh {
  cursor: pointer;
  font-weight: bolder;
}

.btn-list {
  &:hover {
    cursor: pointer;
    font-weight: bolder;
    background-color: #00000006;
  }
}

.wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-container {
  height: 70vh;
  overflow-y: auto;
  border-radius: 5px;

  padding: 1rem;
}

.chat-selector-container {
  height: 70vh;
  overflow-y: auto;
  border-radius: 5px;
}
</style>
