import * as signalR from '@aspnet/signalr';

export default {
  mounted() {
    const vm = this;

    vm.chatLogConnection = new signalR.HubConnectionBuilder()
      .withUrl('/chatHub')
      .build();

    vm.chatLogConnection.on('GetLog', function (message) {
      vm.newChatLog = message;
    });

    vm.connectToChatLog();

    vm.chatLogConnection.onclose(() => {
      vm.connectToChatLog();
    });
  },
  data() {
    return {
      isStreaming: true,
      chatLogConnection: {},
      newChatLog: {},
    };
  },
  methods: {
    connectToChatLog() {
      const vm = this;
      vm.chatLogConnection
        .start()
        .then(function () {
          vm.isStreaming = true;
        })
        .catch(function (err) {
          // eslint-disable-next-line no-console
          console.error(err.toString());
          setTimeout(() => vm.connectToChatLog(), 5000);
        });
    },
  },
};
